import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { HttpProgressBarService } from '../../shared/services/httpprogress.service';
import { Observable, Subject, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
    private throttleLogout = new Subject();
    constructor(private httpProgressBarService: HttpProgressBarService) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.httpProgressBarService.isLoading.next(true);
        return next.handle(req).pipe(
            finalize(
                () => {
                    setTimeout(() => this.httpProgressBarService.isLoading.next(false), 500);
                }
            ),
            catchError((response: HttpErrorResponse) => {
                if (response.status === 401) {
                    this.throttleLogout.next(null);
                }
                return throwError(response);
            })
        );
    }

}
